//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'ModalFollow',
  props: {
    contentFollow: {
      type: Array,
      default: Array
    },
    handleFunction: {
      type: Function,
      default: () => {}
    }
  },
  data () {
    return {
      defaultContentFollow: [],
      followGroup: [],
      loading: false,
      renderComponent: true
    }
  },
  async mounted () {
    if (this.contentFollow.length > 0) {
      this.defaultContentFollow = this.contentFollow
    } else {
      const getFollowGroupAll = this.$store.dispatch('contents/getFollowGroupAll')
      await getFollowGroupAll
      this.defaultContentFollow = this.$store.state.contents.followGroup_all ? cloneDeep(this.$store.state.contents.followGroup_all) : []
      this.forceRerender()
    }
  },
  methods: {
    setDataFollow (contentUuid) {
      if (this.followGroup.includes(contentUuid)) {
        this.followGroup = this.followGroup.filter(v => v !== contentUuid)
      } else {
        this.followGroup = [...this.followGroup, contentUuid]
      }
      this.followGroup = [...new Set(this.followGroup)]
    },
    async submitFollow () {
      this.loading = true
      await this.handleFollow(this.followGroup, true)
      this.handleFunction(this.followGroup)
      this.followGroup = []
      this.$bvModal.hide('modal-follower')
      setTimeout(() => {
        this.loading = false
      }, 200)
    },
    onShow (evt) {
      this.defaultContentFollow = this.contentFollow.length > 0 ? cloneDeep(this.contentFollow) : cloneDeep(this.$store.state.contents.followGroup_all)
    },
    onHide (evt) {
      if (evt.trigger === 'backdrop') {
        this.defaultContentFollow = cloneDeep(this.contentFollow)
        this.followGroup = []
      }
    },
    forceRerender () {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    }
  }
}
