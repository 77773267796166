//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BannerSetItems',
  props: {
    banner: {
      type: Object,
      default: () => {}
    },
    bannerUuid: {
      type: String,
      default: ''
    },
    openNewTab: {
      type: Boolean,
      default: false
    }
  },
  // serverCacheKey: props => 'BannerSetItems::' + JSON.stringify(props.banner),
  data () {
    return {
      bannerOptions: {
        contain: true,
        imagesLoaded: true,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true
      }
    }
  },
  computed: {
    bannerList () {
      if (this.banner) {
        return this.banner.bannerItems || []
      }
      return []
    },
    prevNextButtons () {
      return this.banner.controlArrow && this.bannerList.length > 1
    }
  },
  created () {
    this.setOption()
  },
  mounted () {
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$refs.flickityBanner) {
          this.$refs.flickityBanner.rerender()
        }
      }, 300)
    })
    // personalizedBanner
    if (this.banner.bannerUuid) {
      this.personalizedBannerTrigger(this.banner.bannerUuid, () => {
        this.$refs.flickityBanner.rerender()
      })
    } else {
      this.personalizedBannerTrigger(this.bannerUuid, () => {
        this.$refs.flickityBanner.rerender()
      })
    }
  },
  methods: {
    setBannerImage (images) {
      const url = images.filter((img) => { return img.size === 'default' })[0].url || ''
      return url
    },
    setImageAlt (data) {
      if (this.$device.isMobile) {
        return data.mobileImage.alt
      }
      return data.desktopImage.alt
    },
    onBannerInit () {
      this.$refs.flickityBanner.on('staticClick', this.clickCounter)
    },
    setOption () {
      this.bannerOptions.pageDots = this.banner.paginationBullet || false
      this.bannerOptions.autoPlay = this.banner.transitionSec !== 0 ? this.banner.transitionSec * 1000 : false
    },
    async clickCounter (event, pointer, cellElement, cellIndex) {
      const data = cellElement.dataset
      await this.handleClickTracking(data)
      const url = data.url
      const uuid = data.bannerUuid
      if (url) {
        await this.$store.dispatch('banner/clickCounter', uuid)
        // window.location = url
        if (this.openNewTab) {
          window.open(url, '_blank')
        } else {
          window.location = url
        }
      }
    },
    handleClickTracking (data) {
      const eventTracking = data
      const isEtk = eventTracking.eventTrackingIsEtk === 'true'
      const dataTeamName = eventTracking.eventTrackingTeamName // Data Category(Team)
      const dataAction = this.banner.bannerSetName ? `Banner ${this.banner.bannerSetName}` : '' // Data Action(Section)
      const label = data.eventTrackingLabel
      if (isEtk) {
        this.clickTracking({
          title: label,
          sectionName: dataAction,
          teamName: dataTeamName
        })
      }
    }
  }
}
