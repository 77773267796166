var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bannerList.length > 0)?_c('div',{staticClass:"banner-fixed"},[_c('client-only',[_c('Flickity',{ref:"flickityBanner",attrs:{"options":_vm.bannerOptions},on:{"init":_vm.onBannerInit}},_vm._l((_vm.bannerList),function(item,i){return _c('div',{key:i,staticClass:"banner-item",class:{ 'mouse-pointer' : (item.url && item.url !== '#')},attrs:{"data-url":item.url,"data-banner-uuid":item.bannerItemUuid,"data-event-tracking-label":item.name,"data-event-tracking-is-etk":(item.event_tracking || {}).is_etk,"data-event-tracking-team-name":(item.event_tracking || {}).data_team_name}},[_c('Picture',{attrs:{"alt":_vm.setImageAlt(item),"lazy-image":false,"sources":[
            {
              size: 'md',
              type: 'image/jpeg',
              url: _vm.setBannerImage(item.mobileImage.images)
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: _vm.setBannerImage(item.desktopImage.images)
            }
          ]}})],1)}),0),_vm._v(" "),(_vm.prevNextButtons)?_c('button',{staticClass:"flick-btn-prev flickity-button btn btn-sm btn-prev rounded-circle d-flex align-items-center justify-content-center p-0 previous",attrs:{"type":"button"},on:{"click":function($event){return _vm.$refs.flickityBanner.previous()}}},[_c('svg',{staticClass:"feather feather-chevron-left",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])]):_vm._e(),_vm._v(" "),(_vm.prevNextButtons)?_c('button',{staticClass:"flick-btn-next flickity-button btn btn-sm btn-prev rounded-circle d-flex align-items-center justify-content-center p-0 next",attrs:{"type":"button"},on:{"click":function($event){return _vm.$refs.flickityBanner.next()}}},[_c('svg',{staticClass:"feather feather-chevron-right",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"9 18 15 12 9 6"}})])]):_vm._e()],1)],1):_c('div')}
var staticRenderFns = []

export { render, staticRenderFns }