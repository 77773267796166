//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TitleHighlight',
  props: {
    titleData: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            text: {
              type: String,
              required: false,
              default: null
            },
            class: {
              type: String,
              required: false,
              default: null
            }
          }
        ]
      }
    }
  },
  serverCacheKey: props => 'TitleHighlight::' + props.titleData.map((r) => { return r.text + '_' + r.class }).join('_'),
  methods: {
    checkWhitespace (text) {
      return typeof text === 'string' ? (text.match(/\s+/g)) : false
    },
    checkTitleDataIsDefault () {
      return typeof this.titleData[0].text === 'string'
    }
  }
}
