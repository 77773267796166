//
//
//
//

export default {
  name: 'Divider',
  props: {
    vertical: Boolean
  }
}
