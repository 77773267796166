import { render, staticRenderFns } from "./ContentElearning.vue?vue&type=template&id=3ccb0d50&scoped=true&"
import script from "./ContentElearning.vue?vue&type=script&lang=js&"
export * from "./ContentElearning.vue?vue&type=script&lang=js&"
import style0 from "./ContentElearning.vue?vue&type=style&index=0&id=3ccb0d50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ccb0d50",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TitleHighlight: require('/usr/src/set-nuxt/components/Highlight/TitleHighlight.vue').default,SubTitleHighlight: require('/usr/src/set-nuxt/components/Highlight/SubTitleHighlight.vue').default,Picture: require('/usr/src/set-nuxt/components/Image/Picture.vue').default,CardAuthor: require('/usr/src/set-nuxt/components/Card/CardAuthor.vue').default,Card: require('/usr/src/set-nuxt/components/Card/Card.vue').default,ButtonViewMore: require('/usr/src/set-nuxt/components/Button/ButtonViewMore.vue').default})
