//
//
//
//
//
//

export default {
  name: 'SubTitleHighlight',
  props: {
    title: {
      type: String,
      required: false,
      default: null
    }
  },
  serverCacheKey: props => 'SubTitleHighlight::' + props.title
}
