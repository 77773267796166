//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardAuthor',
  props: {
    pageType: {
      type: String,
      default: ''
    },
    contentType: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    }
  },
  serverCacheKey: props => 'CardAuthor::' + `${props.contentType}_${props.link}_${props.text}`,
  computed: {
    displayText () {
      if (this.contentType === 'ContentDetail' || (this.contentType === 'Content' && this.pageType !== 'Glossary') || this.contentType === 'FollowGroup' || this.contentType === 'OtherPage' || this.contentType === 'ExternalPage') {
        return 'เนื้อหา'
      }
      if ((this.contentType === 'Content' && this.pageType === 'Glossary') || this.contentType === 'Glossary') {
        return 'คำศัพท์'
      }
      if (this.contentType === 'VideoPlaylist' || this.contentType === 'PodcastPlaylist') {
        return 'เพลย์ลิสต์'
      }
      if (this.contentType === 'Media' || this.contentType === 'File') {
        return 'เอกสาร'
      }
      return this.text
    },
    isLink () {
      return (this.contentType === 'VideoPlaylist' || this.contentType === 'PodcastPlaylist' || this.contentType === 'VideoDetail' || this.contentType === 'PodcastDetail' || this.contentType === 'Video' || this.contentType === 'EBookDetail' || this.contentType === 'Ebook' || this.contentType === 'ArticleDetail' || this.contentType === 'Article' || (this.contentType === 'Content' && this.pageType === 'Glossary') || this.contentType === 'Glossary' || this.contentType === 'Other' || this.contentType === 'Media' || this.contentType === 'ELearning' || this.contentType === 'Podcast') && this.link
    }
  }
}
