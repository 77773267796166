//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ContentElearning',
  data () {
    return {
      categoryLink: process.env.SET_ELEARNING_URL,
      titleElearningConfig: [
        {
          text: 'e-Learning',
          class: 'text-primary'
        },
        {
          text: ' น่าเรียน'
        }
      ],
      contentLists: [
        {
          pageuuid: '1',
          text: 'กลุ่มหลักสูตรการเรียนรู้',
          contentCategory: 'กลุ่มหลักสูตร',
          contentType: 'ELearning',
          thumbnail: [
            {
              size: 'sm',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/elearning-default.jpg')
            },
            {
              size: 'md',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/elearning-default.jpg')
            },
            {
              size: 'lg',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/elearning-default.jpg')
            },
            {
              size: 'xl',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/elearning-default.jpg')
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/elearning-default.jpg')
            }
          ]
        },
        {
          pageuuid: '2',
          text: 'กลุ่มหลักสูตรการวางแผนการเงิน',
          contentCategory: 'วางแผนการเงิน',
          contentType: 'ELearning',
          url: 'https://elearning.set.or.th/SETGroup/categories/1',
          cateUrl: 'financialplanning',
          thumbnail: [
            {
              size: 'sm',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-1.jpg')
            },
            {
              size: 'md',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-1@2x.jpg')
            },
            {
              size: 'lg',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-1@2x.jpg')
            },
            {
              size: 'xl',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-1@3x.jpg')
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-1.jpg')
            }
          ]
        },
        {
          pageuuid: '3',
          text: 'กลุ่มหลักสูตรการลงทุนในหุ้น',
          contentCategory: 'หุ้น',
          contentType: 'ELearning',
          url: 'https://elearning.set.or.th/SETGroup/categories/3',
          cateUrl: 'stock',
          thumbnail: [
            {
              size: 'thumbnail',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2.jpg')
            },
            {
              size: 'sm',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2.jpg')
            },
            {
              size: 'md',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2@2x.jpg')
            },
            {
              size: 'lg',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2@2x.jpg')
            },
            {
              size: 'xl',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2@3x.jpg')
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-2.jpg')
            }
          ]
        },
        {
          pageuuid: '4',
          text: 'กลุ่มหลักสูตรการลงทุนในกองทุนรวม',
          contentCategory: 'กองทุนรวม',
          contentType: 'ELearning',
          url: 'https://elearning.set.or.th/SETGroup/categories/5',
          cateUrl: 'mutualfund',
          thumbnail: [
            {
              size: 'sm',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-3.jpg')
            },
            {
              size: 'md',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-3@2x.jpg')
            },
            {
              size: 'lg',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-3@2x.jpg')
            },
            {
              size: 'xl',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-3@3x.jpg')
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-3.jpg')
            }
          ]
        },
        {
          pageuuid: '5',
          text: 'กลุ่มหลักสูตรการลงทุนในอนุพันธ์',
          contentCategory: 'อนุพันธ์',
          contentType: 'ELearning',
          url: 'https://elearning.set.or.th/SETGroup/categories/4',
          cateUrl: 'derivative',
          thumbnail: [
            {
              size: 'sm',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-4.jpg')
            },
            {
              size: 'md',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-4@2x.jpg')
            },
            {
              size: 'lg',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-4@2x.jpg')
            },
            {
              size: 'xl',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-4@3x.jpg')
            },
            {
              size: 'default',
              type: 'image/jpeg',
              url: require('@/static/images/elearning/e-learning-4.jpg')
            }
          ]
        }
      ],
      getButtonViewMoreActionLink: process.env.SET_ELEARNING_URL
    }
  },
  methods: {
    setMediaPlay () {
      this.$bvModal.show('modal-video')
    },
    onClickedCategory (path) {
      const domain = window.location.href.replace('home', path)
      this.openTab(domain, '_blank')
    },
    handleClickTracking (item) {
      this.clickTracking({
        title: item.text,
        pageType: item.pageType,
        sectionName: 'e-Learning น่าเรียน',
        url: item.url,
        gtmDataLayer: item.gtmDataLayer,
        teamName: process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
}
