//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ButtonViewMore',
  props: {
    text: {
      type: String,
      required: true
    },
    actionLink: {
      type: String,
      required: true,
      default: '#'
    }
  },
  serverCacheKey: props => 'ButtonViewMore::' + `${props.text}_${props.actionLink}`
}
