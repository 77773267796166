//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'MiddleBanner',
  data () {
    return {
      banner: null
    }
  },
  computed: {
    bannerDesktopClass () {
      return this.banner.desktopPersonalized
    },
    bannerMobileClass () {
      return this.banner.mobilePersonalized
    }
  },
  async mounted () {
    await this.getBanner()
  },
  methods: {
    async getBanner () {
      const bannerUuid = process.env.MIDDLE_BANNER_UUID
      const url = process.env.API_ENDPOINT + `banner/${process.env.WEB_CHANNEL}/${this.$i18n.locale}/${bannerUuid}`
      await this.$axios.get(url).then((res) => {
        const { data } = res
        this.banner = data
      }).catch(() => {
        this.banner = null
      })
    }
  }
}
