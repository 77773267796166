//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
export default {
  name: 'HomePage',
  async fetch () {
    await this.loadData()
  },
  data () {
    return {
      renderComponent: true,
      knwlFollowing: {},
      contentsFollowModal: [],
      knwlFollowingLoading: true,
      showRecommendContent: false,
      popularContents: {},
      contentLatest: {},
      loading: true,
      routeHash: false
    }
  },
  computed: {
    ...mapGetters({
      content: 'contents/getContentData'
    }),
    bannerHero () {
      const b = this.$store.state.home.heroBanner ? this.$store.state.home.heroBanner : {}
      return b
    },
    bannerAds () {
      const b = this.$store.state.home.adsBanner ? this.$store.state.home.adsBanner : {}
      return b
    },
    solutions () {
      const solutions = this.$store.state.home.investnow_solution ? this.$store.state.home.investnow_solution : []
      return solutions.filter(value => value.items.length > 0) || []
    },
    bannerDesktopClass () {
      return this.bannerAds.desktopPersonalized
    },
    bannerMobileClass () {
      return this.bannerAds.mobilePersonalized
    }
  },
  mounted () {
    this.routeHash = this.$route.hash.includes('#elearning')
    this.$nextTick(() => {
      if (!this.routeHash) {
        this.hideLoading()
      }
    })
  },
  updated () {
    this.$nextTick(() => {
      if (this.routeHash) {
        setTimeout(() => {
          this.scrollToElement()
          setTimeout(() => {
            this.hideLoading()
          }, 500)
        }, 1000)
      }
    })
  },
  methods: {
    hideLoading () {
      this.$nuxt.$loading.finish()
    },
    async loadData () {
      await this.$store.dispatch('home/getHomeResource')
      this.setData('home')
    },
    scrollToElement () {
      if (this.routeHash) {
        const hash = this.$route.hash
        const element = document.querySelector(hash)
        if (element) {
          element.scrollIntoView()
        }
      }
    },
    setData (source) {
      this.knwlFollowing = this.$store.state[source].knwlFollowing ? cloneDeep(this.$store.state[source].knwlFollowing) : {}
      this.contentsFollowModal = this.$store.state[source].followGroup_all ? cloneDeep(this.$store.state[source].followGroup_all) : []
      this.showRecommendContent = (this.knwlFollowing.followGroups || []).find(e => e.following === true)
      this.popularContents = this.$store.state.home.contents_popular ? cloneDeep(this.$store.state.home.contents_popular) : {}
      this.contentLatest = this.$store.state.home.contents_latest ? cloneDeep(this.$store.state.home.contents_latest) : {}
      this.knwlFollowingLoading = false
    },
    async updateFollow () {
      // update follow data
      await this.update()
      this.setData('contents')
      this.forceRerender()
    },
    async update () {
      const getContentMember = this.$store.dispatch('contents/getContentMember')
      const getFollowGroupAll = this.$store.dispatch('contents/getFollowGroupAll')
      await getContentMember
      await getFollowGroupAll
    },
    forceRerender () {
      // Remove my-component from the DOM
      this.renderComponent = false

      this.$nextTick(() => {
        // Add the component back in
        this.renderComponent = true
      })
    },
    handleClickTracking (item, sectionName) {
      this.clickTracking({
        title: item.name,
        pageUuid: item.pageUuid,
        pageType: item.pageType,
        sectionName: sectionName || '',
        url: item.url,
        gtmDataLayer: item.gtmDataLayer,
        teamName: process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
  // head () {
  //   return {
  //     title: this.$t('links.home') + ' - ' + this.content.title
  //   }
  // }
}
