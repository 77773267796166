//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FollowContent',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    subTitle: {
      type: String,
      default: () => ''
    },
    divider: {
      type: Boolean
    }
  },
  // serverCacheKey: props => 'FollowContent::' + JSON.stringify({
  //   title: props.title,
  //   subTitle: props.subTitle,
  //   divider: props.divider
  // }),
  serverCacheKey: props => 'FollowContent::' + `${props.title}_${props.subTitle}_${props.divider}`,
  data () {
    return {
      loading: true
    }
  },
  mounted () {
    this.registerEvent()
    setTimeout(() => {
      this.handleFollows()
      this.loading = false
    }, 300)
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    handleFollows () {
      const eleFollowList = document.querySelector('.follow-list')
      if (!eleFollowList) {
        return false
      }
      const buttonFollow = eleFollowList.querySelectorAll('.btn-follow') || []
      const buttonGap = 8
      buttonFollow.forEach((el) => { el.classList.remove('d-none') })
      if (window.innerWidth >= 768) {
        for (let i = 0; i < buttonFollow.length; i++) {
          const button = buttonFollow[i]
          const isOverflow = button.offsetLeft + button.clientWidth + buttonGap > eleFollowList.clientWidth
          if (isOverflow) {
            button.classList.add('d-none')
          }
        }
      }
    },
    registerEvent () {
      window.addEventListener('resize', this.handleFollows)
    },
    removeEvent () {
      window.addEventListener('resize', this.handleFollows)
    }
  }
}
